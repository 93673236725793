.ais-SearchBox {
  margin: 1em 0;
}

.ais-Pagination {
  margin-top: 1em;
}

.left-panel {
  float: left;
  width: '70%';
}

.right-panel {
  margin-left: '30%';
}

.ais-InstantSearch {
  max-width: 960px;
  overflow: hidden;
  margin: 0 auto;
}

em {
  text-decoration: underline;
  font-style: inherit;
}

.ais-RefinementList-list {
  list-style-type: none;
}

.ais-RefinementList-labelText {
  padding-left: 5px;
}

.ais-Hits-list {
  list-style-type: none;
  padding-left: 0px !important;
}

.hit-name {
  margin-bottom: 0.5em;
}

.hit-description {
  color: #888;
  font-size: 14px;
  margin-bottom: 0.5em;
}

.ais-HierarchicalMenu-count,
.ais-Menu-count,
.ais-RefinementList-count,
.ais-ToggleRefinement-count {
  padding: 0.1rem 0.4rem;
  font-size: 0.8rem;
  color: #3a4570;
  background-color: #dfe2ee;
  border-radius: 8px;
}

/* Remove This Maybe?*/

.react-autosuggest__container {
  position: relative;
  margin-bottom: 1rem;
}

.react-autosuggest__input {
  width: 100%;
  height: 41px;
  display: inline-flex;
  position: relative;
  box-sizing: border-box;
  align-items: center;
  background: #f0f0f0ff;
  border: 0;
  padding: 11px 8px;
  line-height: 1.1876em;
  font-family: Inter,sans-serif;
  color: #333333ff;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -0.02em;
}

.react-autosuggest__input--focused {
  outline: none;
}

.react-autosuggest__input--open {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.react-autosuggest__suggestions-container {
  display: none;
}

.react-autosuggest__suggestions-container--open {
  position:absolute;
  display: block;
  box-sizing: border-box;
  top: 45px;
  width: 100%;
  border: 1px solid #aaa;
  background-color: #fff;
  font-family: Inter,sans-serif;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -0.02em;
  z-index: 2;
}

.react-autosuggest__section-title {
  padding: 10px 20px;
  font-weight: 600;
}

.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.react-autosuggest__suggestion {
  cursor: pointer;
  padding: 10px 20px;
}

.react-autosuggest__suggestion--highlighted {
  background-color: #ddd;
}