.ais-SearchBox {
    margin: 1em 0;
  }
  
  .ais-Pagination {
    margin-top: 1em;
  }
  
  .left-panel {
    float: left;
    width: '70%';
  }
  
  .right-panel {
    margin-left: '30%';
  }
  
  .ais-InstantSearch {
    max-width: 960px;
    overflow: hidden;
    margin: 0 auto;
  }
  
  em {
    text-decoration: underline;
    font-style: inherit;
  }
  
  .ais-RefinementList-list {
    list-style-type: none;
    padding-inline-start: 20px;
  }
  
  .ais-RefinementList-labelText {
    padding-left: 5px;
  }
  
  .ais-Hits-list {
    list-style-type: none;
    padding-left: 0px;
  }
  
  .hit-name {
    margin-bottom: 0.5em;
  }
  
  .hit-description {
    color: #888;
    font-size: 14px;
    margin-bottom: 0.5em;
  }
  
  .ais-HierarchicalMenu-count,
  .ais-Menu-count,
  .ais-RefinementList-count,
  .ais-ToggleRefinement-count {
    padding: 0.1rem 0.4rem;
    font-size: 0.8rem;
    color: #3a4570;
    background-color: #dfe2ee;
    border-radius: 8px;
  }
  