.ck.ck-color-grid__tile {
  border: 1px solid #c7c7c7;
}

/* Fix so ck editor tooltip is visible when editor is part of full screen modal*/
.ck.ck-balloon-panel.ck-balloon-panel_visible {
  z-index: 9999;
}

.ck.ck-button.smartfield-icon + .ck.ck-dropdown__panel{
  height: 80vh;
  overflow-y: scroll;
}

.ck.ck-button.ck-fontsize-option .ck-button__label {
  font-size: 1.1em !important;
}
